
(function () {

    // Obtenemos preferencia de lenguaje
    
    TC.Cfg.notifyApplicationErrors = false;
    
    TC.Cfg.controls.search = false;
    
    var layoutLocation = TC.apiLocation + '/tc/layout/responsive';
    
    //var layout = "layout/pamplona";
    var map = new TC.Map("mapa", {
        layout:  {
            script: layoutLocation + '/script.js',
            style: layoutLocation + '/style.css',
            markup: 'layout/fosas//markup.html',
            config: 'layout/fosas/config.json', 
            i18n: layoutLocation + '/resources',
            accordion: true
        }
    });    
    map.loaded(function () {
        console.log("map loaded");
        TC.loadCSS("./layout/fosas/fosas.css");
        (function () {        
            
            TC._hbs.registerHelper('fileExtension', function (value) {
                return value.toLowerCase().substring(value.lastIndexOf(".") + 1);
            })

        })();
    });    
})();



