TC.control = TC.control || {};

if (!TC.control.Graves) {
    TC.syncLoadJS('js/TC/control/graves');
}


TC.control.GraveNotInspected = function (div, options) {
    var self = this;

    options.filter = new TC.filter.EqualTo("idestado", options.idestado);
    TC.control.Graves.apply(self, [div, options]);
    
}

TC.inherit(TC.control.GraveNotInspected, TC.control.Graves);

(function () {
    var ctlProto = TC.control.GraveNotInspected.prototype;
    ctlProto.register = function (map) {
        var self = this;

        return new Promise(function (resolve, reject) {
            TC.control.Graves.prototype.register.call(self, map).then(function () {
                console.log("Grave NotInspected registered");
                resolve();
            })
        });
    }

})();