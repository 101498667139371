TC.control = TC.control || {};

if (!TC.Control) {
    TC.syncLoadJS(TC.apiLocation + 'TC/Control');
}


TC.control.Contact = function (div, options) {
    var self = this;
    self._sitekey = options.siteKey;
    self._urlAPI = options.urlAPI;
    TC.Control.apply(self, arguments);

}

TC.inherit(TC.control.Contact, TC.Control);

(function () {
    var _ctl = TC.control.Contact.prototype;
    var getControlInstance = null;
    _ctl.template = {};
    _ctl.CLASS = 'tc-ctl-contact';
    _ctl.template[_ctl.CLASS] = "js/tc/templates/Contact.hbs";
    _ctl.template[_ctl.CLASS + "-dialog"] = "js/tc/templates/ContactDialog.hbs";
    
    _ctl.register = function (map) {
        var self = this;
        TC.loadJS(true, ['./js/lib/jQuery/jquery.form.js'], function () {
        })
        return new Promise(function (resolve, reject) {
            TC.Control.prototype.register.call(self, map).then(function () {
                console.log("Contact registered");
                resolve();
            })
        });
    };

    _ctl.render = function () {
        var _self = this;
        _self.renderData(null, function () {
            var btn = _self.div.getElementsByClassName("tc-ctl-searchPlaces-btn")[0];
            btn.addEventListener('click', function () {
                _self.getRenderedHtml(_self.CLASS + "-dialog", {
                    //si la ventana es inferior a 360px añadimos el atributo data-size=compact el recaptcha
                    size: (document.body.offsetWidth < 360 ? "compact" : "normal"),
                    sitekey: _self._sitekey,
                    urlAPI: _self._urlAPI
                }, function (html) {
                    var modal = $(html);
                    modal.appendTo('body');
                    modal.addClass(_ctl.CLASS);
                    TC.Util.showModal(modal[0], {
                        closeCallback: function () {
                            modal.remove();                            
                        }
                    });

                    _self.getModal = function () {
                        return modal;
                    };

                    _self.closeModal = function () {
                        modal.remove();
                    }
                    var form = modal[0].getElementsByTagName("form")[0];
                    form.addEventListener('submit', submitForm);
                    var _checkField = function () {
                        if (this.validity.valueMissing && this.value == '') {
                            this.setCustomValidity('Complete este campo');
                        }
                        else if (this.validity.patternMismatch) {
                            this.setCustomValidity('Formato de email no válido');
                        }
                        else {
                            this.setCustomValidity('');
                        }
                        return true;
                    }
                    if (TC.browser.name === "Firefox")
                        form.elements.email.addEventListener('blur', function () {
                            this.value = this.value.trim();
                        });
                    form.elements.forEach(function (input) {
                        input.addEventListener('invalid', _checkField);
                        input.addEventListener('input', _checkField)
                    });
                    form.elements.buscar.addEventListener('click', function () {
                        form.noValidate = false;
                        if (!form.checkValidity()) {
                            form.classList.add("was-validated");
                        }
                    })
                    form.elements.limpiar.addEventListener('click', function () {
                        form.classList.remove("was-validated");
                        form.noValidate = true;
                    });
                });
            });

        });
        getControlInstance = function () {
            return _self;
        }
    };
    var submitForm = function (event) {
        event.preventDefault();
        event.stopPropagation();
        if (this.checkValidity()) {
            $(this).ajaxSubmit({
                success: function (response) {
                    var _self = getControlInstance();
                    if (response.ok) {
                        _self.closeModal();
                        _self.map.toast("Información recibida correctamente, muchas gracias por su aportación.", { type: TC.Consts.msgType.INFO });

                    }
                    else if (response.error) {
                        _self.map.toast("Parece que nuestro servidor de correo no responde. Por favor, inténtelo de nuevo más tarde.", { type: TC.Consts.msgType.WARNING, container: _self.getModal() });

                    }
                    else {
                        var msgs = [];
                        if (!response.captcha)
                            msgs.push("Marque la casilla \"No soy un robot\"");
                        for (var key in response) {
                            if (typeof (response[key]) === "string")
                                msgs.push(response[key]);
                        }
                        if (msgs.length > 0)
                            _self.map.toast(msgs.join("<br/>"), { type: TC.Consts.msgType.WARNING, container: _self.getModal()[0].getElementsByClassName("tc-modal-window")[0] });
                    }



                }
            })
        }
        return false;
        //$(this).ajaxSubmit();
        // return false to prevent normal browser submit and page navigation 
        //return false;
    };
})();