TC.control = TC.control || {};

if (!TC.Control) {
    TC.syncLoadJS(TC.apiLocation + 'TC/Control');
}




TC.control.SearchVictims = function (div,options) {

    var self = this;
    self._WFSURL = options.url;
    TC.Control.apply(self, arguments);
    
}

TC.inherit(TC.control.SearchVictims, TC.Control);

(function () {

    var _ctl = TC.control.SearchVictims.prototype;

    var resultsLayer = null;

    _ctl.template = {};
    _ctl.CLASS = 'tc-ctl-searchVictims';
    var getControlInstance = null;        

    _ctl.template[_ctl.CLASS] = "js/tc/templates/SearchVictims.hbs"; 
    

    var autocompleteLoadPromise = function () {
        return new Promise(function (resolve, reject) {
            if (!TC.UI || !TC.UI.autocomplete)
                TC.loadJS(
                    true,
                    [TC.apiLocation + 'TC/ui/autocomplete.js'],
                    function () {
                        console.log("autocomplete loaded");
                        resolve();
                    });
            else
                resolve();
        });

    };

    _ctl.register = function (map) {
        var self = this;
        return new Promise(function (resolve) {
            autocompleteLoadPromise().then(function () {
                TC.Control.prototype.register.call(self, map).then(function () {
                    console.log("SearchVictims registered");
                    resolve();
                });
            });
        });
    };

    _ctl.render = function () {
        var _self = this;
        getControlInstance = function () {
            return _self;
        }
        _self.renderData(null, function () {
            _bindBtnEvents();
        });        
    };
    var _bindBtnEvents = function () {
        var _self = getControlInstance();
        //bindeamos botones
        /*var searchButton = _self.div.getElementsByClassName("tc-ctl-searchVictims-btn");
        if (searchButton.length > 0) {
            searchButton = searchButton[0];
            searchButton.onclick = function () {
                if (validate())
                    sendQuery();
            };
        }*/
        var form = _self.div.getElementsByTagName("form")[0];

        var submitForm = function () {
            if (validate(form) === true) {
                form.classList.remove('was-validated');
                sendQuery();
                return;
            }
            form.classList.add('was-validated');
        }

        form.addEventListener('submit', function (event) {
            event.preventDefault();
            event.stopPropagation();
            submitForm();
        }, false);        
        var cleanButton = _self.div.getElementsByClassName("tc-ctl-searchVictims-cln");
        if (cleanButton.length > 0) {
            cleanButton = cleanButton[0];
            cleanButton.onclick = function () {
                $(_self.div.getElementsByTagName("input")).val("");
                if (_self.removeResultsPanel) _self.removeResultsPanel();
            };
        }

        var source = function (pattern) {
            var filter = new TC.filter.IsLike("criterio", "*" + pattern.trim().removeDiacritics().removeGTLT() + "*", undefined, undefined, undefined, false);
            return $.post(_self._WFSURL, {
                service: "WFS",
                version: "1.0.0",
                request: "GetFeature",
                properties: "municipio",
                typeName: "listMunipiosResidencia",
                outputFormat: "application/json",
                FILTER: filter.getText(),
            }, null, "json");

        }
        _createAutoComplete(_self.div.querySelector("#txtMunSearchVictims"), _self.div.querySelector(".tc-ctl-searchVictims-auList"), source, false, function () {
            submitForm();
        });

    }
    var validate = function (form) {
        if ($("input:valid", form).length > 0)
            return true;
        else
        {
            var _self = getControlInstance();
            var toastText = "Debe especificar al menos uno de los criterios";
            $("input:invalid", form).each(function (ì, item) {
                if (item.validity.patternMismatch)
                    toastText = "Campo " + item.placeholder.toLowerCase() + " " + item.title.toLowerCase();
            })
            _self.map.toast(toastText, { type: TC.Consts.msgType.WARNING });
            
            return false;
        }
    };
    var sendQuery = function () {
        var _self = getControlInstance();

        //cerrar el panel de resutados de la búsqueda de fosas
        var _ctrlSearchPlaces = _self.map.getControlsByClass(TC.control.SearchPlaces)[0];
        if (_ctrlSearchPlaces.removeResultsPanel) _ctrlSearchPlaces.removeResultsPanel();

        var _filters = _self.map.getControlsByClass(TC.control.Filter)[0];
        if (_filters) _filters.disable();
                
        var _arrConditions = [];

        var form = _self.div.getElementsByTagName("form")[0];
        var _field = form.nombrebusq;
        if (_field.value.trim())
            _arrConditions.push(new TC.filter.IsLike(_field.name, "*" + _field.value.trim().removeDiacritics().removeGTLT() + "*", undefined, undefined, undefined, false));
        _field = form.apellidosbusq;
        if (_field.value.trim())
            _arrConditions.push(new TC.filter.IsLike(_field.name, "*" + _field.value.trim().removeDiacritics().removeGTLT() + "*", undefined, undefined, undefined, false));
        _field = form.lugarresidenciabusq;
        if (_field.value.trim())
            _arrConditions.push(new TC.filter.EqualTo(_field.name, _field.value.trim().removeDiacritics().toLowerCase().removeGTLT()));
        

        
        var _ctrlSearchPlaces = _self.map.getControlsByClass(TC.control.SearchPlaces)[0];
        if (_ctrlSearchPlaces.removeResultsPanel) _ctrlSearchPlaces.removeResultsPanel();

        if (!resultsLayer) {
            _self.map.addLayer({
                id: "WFSSearchResults2",
                type: TC.Consts.layerType.WFS,
                url: _self._WFSURL,
                version: "1.1.0",
                stealth: true,                
                featureType: "victimas",
                propertynames: 'nombre, apellidos, lugarnacimiento, profesion, lugarresidencia, lugarfusilamiento, fechafusilamiento,idfosa,idlugar',
                properties: _arrConditions.length === 1 ? _arrConditions[0] : TC.filter.and.apply(null, _arrConditions),
                outputFormat: TC.Consts.format.JSON
            }).then(function (layer) {
                resultsLayer = layer;
                layer.map.on(TC.Consts.event.LAYERUPDATE, _featuresUpdate);
            });
            _self.map.$events.on(TC.Consts.event.RESULTSPANELCLOSE, function (e) {
                if (TC.browserFeatures.touch())
                    e.control._$div.swipe("enable");
                resultsLayer.clearFeatures();
                resultsLayer.setVisibility(false);
                _self.map.off(TC.Consts.event.LAYERUPDATE, _featuresUpdate);
            });
        }
        else {
            resultsLayer.setVisibility(false);
            resultsLayer.clearFeatures();
            resultsLayer.map.off(TC.Consts.event.LAYERUPDATE, _featuresUpdate);
            resultsLayer.map.on(TC.Consts.event.LAYERUPDATE, _featuresUpdate);
            resultsLayer.properties = _arrConditions.length === 1 ? _arrConditions[0] : TC.filter.and.apply(null, _arrConditions);
            resultsLayer.setVisibility(true);
            resultsLayer.refresh();
        }

    };
    
    var _featuresUpdate = function (e) {
        var _self = getControlInstance();
        if (e.layer.id === resultsLayer.id) {
            //pintar resultados
            if (e.newData && e.newData.totalFeatures > 0) {
                var fncCallback = function () {                    
                    e.layer.map.off(TC.Consts.event.LAYERUPDATE, _featuresUpdate);
                }
                drawResults(e.newData.features, fncCallback);
                //compruebo si el panel de herramientas ocupa el 100% de la pantalla, si es así lo repliego
                if (document.getElementById("tools-panel").clientHeight === document.body.clientHeight)
                    document.getElementById("tools-panel").classList.add("right-collapsed");
            }
            else {
                _self.map.toast(_self.getLocaleString("noResults"), { type: TC.Consts.msgType.WARNING });
            }
        }
    };
    var drawResults = function (data, fncCallback) {
        var _self = getControlInstance();
        var ctlResultsPanel;
        var deferResultsPanel = new $.Deferred();            

        if (!TC.control.ResultsPanel) {
            TC.loadJS(true, TC.apiLocation + 'TC/control/ResultsPanel', function () {
                deferResultsPanel.resolve(TC.control.ResultsPanel);
            });
        }
        else
            deferResultsPanel.resolve(TC.control.ResultsPanel);

        deferResultsPanel.then(function (ResultsPanel) {
            new Promise(function (resolve) {
                if (!ctlResultsPanel) {
                    var ccontainer = _self.map.getControlsByClass(TC.control.ControlContainer);
                    if (ccontainer.length > 0)
                        ccontainer[0].addControl("ResultsPanel", {
                            "side": "right",
                            "options": {
                                "div": "results-panel-victims"
                            },
                            "content": "table",
                            "titles": {
                                "main": "",
                                "max": ""
                            },
                            "save": {
                                "fileName": "Lugares de la memoria" + ".xls"
                            },
                            "resize": true
                        }).then(function (ctl) {
                            ctlResultsPanel = ctl;
                            resolve();
                        });
                    else {
                        ctlResultsPanel = new TC.control.ResultsPanel({
                            "div": "results-panel-victims",
                            "content": "table",
                            "titles": {
                                "main": "",
                                "max": ""
                            },
                            "save": {
                                "fileName": "Busqueda de victimas" + ".xls"
                            },
                            "resize": true
                        });
                        _self.map.addControl(ctlResultsPanel).then(function () {
                            resolve();
                        });
                    }
                }
            }).then(function () {
                _self.removeResultsPanel = function () {
                    if (ctlResultsPanel) {
                        ctlResultsPanel.close();
                    }
                }
                //es necesario borrar de la cache de dust la plantilla de la tabla por que si se ha utilizado antes la busqueda de fosas estará con la plantillad este control
                //delete dust.cache[ctlResultsPanel.CLASS + "-table"];

                ctlResultsPanel.template[ctlResultsPanel.CLASS + "-table"] = "js/tc/templates/SearchVictimsResults.hbs";
                
                ctlResultsPanel.div.querySelector(".prpanel-title-text").innerHTML = (data.length + (data.length > 1 ? " resultados" : " resultado"));                                    
                
                console.log("pinta panel de resultados");
                ctlResultsPanel.openTable({
                    data: (data.length > 1 ?
                        data.reduce(function (vi, va, index) {
                            return (vi instanceof Array ? vi : [vi.properties]).concat([va.properties])
                        })
                        :
                        [data[0].properties]),
                    css: {
                        trClass: "trClass",
                        tdClass: "tdClass",
                        thClass: "thClass",
                    },
                    callback: function (tabla) {
                        var _gravesCtrl = _self.map.getControlsByClass(TC.control.Graves)[0];
                        var table = $("table", tabla).DataTable({
                            dom: 't',
                            paging: false,
                            ordering: true,
                            order: [[5, 'asc'], [4, 'asc']],
                            info: false,
                            autoWidth: false,
                            searching: false,
                            language: {
                                "url": "//cdn.datatables.net/plug-ins/9dcbecd42ad/i18n/Spanish.json"
                            },
                            columnDefs: [
                                {
                                    type: 'diacritics-neutralise',
                                    "targets": [5, 4]
                                }],
                            columns: [
                                { sortable: false, width: "auto", "class": "icons" },
                                { sortable: false, width: "auto", "class": "icons" },
                                { sortable: false, width: "auto", "class": "icons" },
                                { sortable: false, width: "auto", "class": "icons" },
                                { sortable: true, width: "15%" },
                                { sortable: true, width: "20%" },
                                { sortable: false, width: "9%" },
                                { sortable: false, width: "9%" },
                                { sortable: false, width: "9%" },
                                { sortable: false, width: "9%" },
                                { sortable: false, width: "9%" },
                                { sortable: false, width: "5%" }
                            ]
                        });
                        setTimeout(function () {
                            //se deshabilita el swype para que se pueda hacer scroll horizontal del panel de resultados
                            if (TC.browserFeatures.touch())
                                ctlResultsPanel._$div.swipe("disable");
                        }, 1000);
                        fncCallback();
                        var col = jQuery("tbody button", tabla);
                        col.on("click", function (e) {
                            var index = $(this).parents("tr").data("index")
                            if (index != undefined) {
                                var id = data[index].properties[this.className.indexOf("tc-grave-type-grave") >= 0 ? "idfosa" : "idlugar"]
                                var _grave = _gravesCtrl.getGraveById(id);
                                if (this.className.indexOf("tc-icon-marker-pin") >= 0) {
                                    //zoom a  la fosa
                                    _gravesCtrl.zoomToGrave(_grave);
                                }
                                if (this.className.indexOf("tc-icon-info ") >= 0) {
                                    //abrir ficha fosa
                                    _gravesCtrl.OpenGraveInfo({ feature: _grave });
                                }
                            }
                            e.preventDefault(); // Stop page from scrolling
                            e.stopPropagation();
                        })
                    }

                });
                
            })
            
                   
        });
    }

})();