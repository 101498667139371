
function getParameterByName(name) {
    name = name.replace(/[\[]/, "\\\[").replace(/[\]]/, "\\\]");
    var regexS = "[\\?&]" + name + "=([^&#]*)";
    var regex = new RegExp(regexS);
    var results = regex.exec(window.location.search);
    if (results == null)
        return "";
    else
        return decodeURIComponent(results[1].replace(/\+/g, " "));
};
String.prototype.trim = function () {
    return this.replace(/^\s+|\s+$/g, "");
};
String.prototype.ltrim = function () {
    return this.replace(/^\s+/, "");
};
String.prototype.rtrim = function () {
    return this.replace(/\s+$/, "");
};
String.prototype.removeDiacritics = function () {
    //var lc = this.toLowerCase();
    var accentMapping = { "á": "a", "é": "e", "í": "i", "ó": "o", "ú": "u", "ü": "u", "Á": "A", "É": "E", "Í": "I", "Ó": "O", "Ú": "U", "Ü": "U" };
    var ret = "";
    for (var i = 0; i < this.length; i++) {
        ret += accentMapping[this.charAt(i)] || this.charAt(i);
    }
    return ret;
};
String.prototype.removeGTLT = function () {
    return this.replace(/\</gi, "&lt;").replace(/\>/gi, "&gt;");
}


function CreateCircle(point, radius, nPoints) {
    var angle = (Math.PI * 2) / nPoints
    var x = point[0], y = point[1];
    var arrPoints = [];
    var _angle = 0;
  
    while (_angle < (Math.PI * 2)) {
        var y2 = Math.sin(_angle) * radius;
        var x2 = Math.cos(_angle) * radius;
        arrPoints.push([x + x2, y + y2]);
        _angle = _angle + angle;
    }
    return arrPoints;
};

if (window.HTMLCollection && !HTMLCollection.prototype.forEach) {
    HTMLCollection.prototype.forEach = function (callback, thisArg) {
        thisArg = thisArg || window;
        for (var i = 0; i < this.length; i++) {
            callback.call(thisArg, this[i], i, this);
        }
    };
}


function _createAutoComplete(control, listCtrl, source, refreshOnChange, callback) {
    var fnc2;    
    var listCtrl = listCtrl;   
    var _keypressDelay = 300;
    var currentPromise = null;
    var fnc = function () {
        if (!fnc2)
            return null;
        return fnc2(control.id);
    }
    var _highlightText = function (value, pattern) {
        pattern = new RegExp(pattern, "gi");
        var id = value ? value.id : null;
        value = value.label || value.value || value;
        return "<li><a href=\"#\" data-value=\"" + (id ? id : value) + "\">" + value.replace(pattern, '<b>$&</b>') + "</a></li>";
    };
    var _keyUpDownEvent = function (e, control, listCtrl) {
        if (!e.ctrlKey && !e.altKey && !e.shiftKey) {
            if (e.keyCode === 40) { // down arrow                
                if (control[0] == document.activeElement) {
                    listCtrl.find('li:first a').focus();
                }
                else if (listCtrl.find('li:last a').is(':focus')) {
                    // Scenario 2: We're focused on the last li; move up to search input
                    control.focus();
                }
                else {
                    // Scenario 3: We're in the list but not on the last element, simply move down                    
                    listCtrl
                        .find('li > a:focus')
                        .parent('li')
                        .next()
                        .find('a').focus();
                }
                e.preventDefault(); // Stop page from scrolling
                e.stopPropagation();
            }
            else if (e.keyCode === 38) { // up arrow                
                if (control[0] == document.activeElement) {
                    // Scenario 1: We're focused on the search input; move down to the last li
                    listCtrl.find('li:last a').focus();
                } else if (document.activeElement == listCtrl.find('li:not([header]):first a').get(0)) {
                    listCtrl.find('li:not([header]):last a').focus();
                } else {
                    // Scenario 3: We're in the list but not on the first element, simply move up
                    listCtrl
                        .find('li > a:focus')
                        .parent('li')
                        .prev()
                        .find('a').focus();
                }
                e.preventDefault(); // Stop page from scrolling
                e.stopPropagation();
            }
        }
    }
    var _timeOut = null;
    var _lastPattern = null;
    TC.UI.autocomplete.call(control,{
        minLength: 1,        
        target: listCtrl,
        source: function (request, response) {
            var _self = this;
            var request = request;
            var response = response;
            if (request === _lastPattern)
                return;
            _lastPattern = request;
            if (_timeOut) 
                clearTimeout(_timeOut);                
            _timeOut =setTimeout(function () {
                const currentRequest = request.trim();
                _self.getCurrentRequest = function () {
                    return currentRequest;
                }
                _self.target.innerHTML=('<li><a class="tc-ctl-search-li-loading" href="#">Buscando...<span class="tc-ctl-search-loading-spinner tc-ctl-search-loading"></span></a></li>');
                _self.target.classList.add("tc-visible");
                if (!_self.target.style.marginTop) {
                    _self.target.style.maxHeight = "";
                    //calculo el desplazamiento hacia arriba de la lista de coincidencias para que quede justo rebajo del cuadro de texto
                    _self.target.style.marginTop = null;
                    var margin = Math.round(_self.target.getBoundingClientRect().top - control.getBoundingClientRect().top - control.getBoundingClientRect().height)
                    _self.target.style.marginTop = "-" + margin + "px"
                }
                var matcher = new RegExp($.fn.dataTable.util.escapeRegex(request), "i");
                if (currentPromise)
                    currentPromise.abort();
                currentPromise = source(currentRequest)
                currentPromise.then(function (data) {
                    currentPromise = null;
                    var _possibleValues = data.features.reduce(function (init, val) {
                        if (init.indexOf(val.properties.municipio) < 0)
                            init.push(val.properties.municipio);
                        return init
                    }, []);

                    if (_possibleValues.length > 0) {
                        response(_possibleValues);
                        if (refreshOnChange)
                            (function (data, id) {
                                var colData = {}
                                colData[id] = data
                                fnc2 = function (id) {
                                    return colData[id];
                                };
                            })(request, control[0].id);
                    }
                    else {
                        _self.target.innerHTML=('<li><a title="Revise el criterio" class="tc-ctl-search-li-empty">No se han encontrado resultados</a></li>');
                    }
                });

                
            }, _keypressDelay)
            
        },
        callback: function (e) {
            control.value = (e.currentTarget.text);
            this.target.classList.remove("tc-visible");
            var value = e.currentTarget.dataset["value"];
            (function (data, id) {
                var colData = {}
                colData[id] = data
                fnc2 = function (id) {
                    return colData[id];
                };
            })(e.currentTarget.dataset["value"], control.id);
            if (callback)
                callback(true);
        },
        buildHTML: function (data) {            
            //var pattern = control.value.trim(); 
            if (data.results.length > 1)
                return data.results.reduce(function (pv, cp, i) {
                    return (i > 1 ? pv : _highlightText(pv, _lastPattern)) + _highlightText(cp, _lastPattern);
                });
            else
                return _highlightText(data.results[0], _lastPattern);
        }
    });
    control.addEventListener("targetCleared.autocomplete", function () {
        listCtrl.classList.remove("tc-visible");
        if (!refreshOnChange)
            fnc2 = null;
    });
    control.addEventListener('keypress', function (e) {
        if (e.which == 13) {
            e.preventDefault();
            e.stopPropagation();
            if (refreshOnChange) {
                callback();
                setTimeout(function () {
                    listCtrl.classList.remove("tc-visible");
                    clearTimeout(_timeOut);
                }, 200);
            }
        }
    });
    control.addEventListener("search", function (e) {
        if (control.value.length === 0) {
            TC.UI.autocomplete.call(e.target, "clear");
        }
    });
    control.addEventListener("input", function (e) {
        if (control.value.length === 0) {
            TC.UI.autocomplete.call(e.target, "clear");
        }
    });
    control.addEventListener("change", function () {
        if (refreshOnChange)
            (function (data, id) {
                var colData = {}
                colData[id] = data
                fnc2 = function (id) {
                    return colData[id];
                };
            })(this.value, control.id);
    });    

    // Detect up/down arrow
    control.addEventListener('keydown', function (e) {
        _keyUpDownEvent(e, $(control), $(listCtrl))
    });

    listCtrl.addEventListener('keydown', function (e) {
        _keyUpDownEvent(e, $(control), $(listCtrl))
    });    
    return fnc;
}
