TC.control = TC.control || {};

if (!TC.Control) {
    TC.syncLoadJS(TC.apiLocation + 'TC/Control');
}


TC.control.Filter = function (div, options) {
    var self = this;
    self._WFSURL = options.url;
    TC.Control.apply(self, arguments);
}

TC.inherit(TC.control.Filter, TC.Control);

(function () {
    var _ctl = TC.control.Filter.prototype;

    _ctl.template = {};
    _ctl.CLASS = 'tc-ctl-filter';
    var resultsLayer = null;
    var _filtered = false;
    var autocompleteEnabled = false;
    var _layerListMunicipalities = "listMunipiosResidencia";
    
    _ctl.template[_ctl.CLASS] = "js/tc/templates/Filter.hbs";

    var autocompleteLoadPromise = function () {
        return new Promise(function (resolve, reject) {
            if (!TC.UI || !TC.UI.autocomplete)
                TC.loadJS(
                    true,
                    [TC.apiLocation + 'TC/ui/autocomplete.js'],
                    function () {
                        console.log("autocomplete loaded");
                        resolve();
                    });
            else
                resolve();
        });

    }

    _ctl.register = function (map) {
        var self = this;
        return new Promise(function (resolve, reject) {
            autocompleteLoadPromise().then(function () {
                TC.Control.prototype.register.call(self, map).then(function () {
                    console.log("Filter registered");
                    resolve();
                });
            });            
        });
    };
    var _getGraveControls = null;
    _ctl.render = function () {
        var _self = this;        

        _getGraveControls = function () {
            return _self.map.getControlsByClass(TC.control.Graves);
        }

        _self.renderData(null, function () {

            var validate = function (form) {
                if ($("input[type='radio']:valid", form).length > 0 && $("input[type='search']:valid", form).length>0)
                    return true;
                else {
                    //_self.map.toast(toastText, { type: TC.Consts.msgType.WARNING });
                    _self.map.toast("Debe especificar un criterio de filtrado", { type: TC.Consts.msgType.WARNING });

                    return false;
                }
            };
            var filter = function (form) {                
                var txtMuni = form.elements.txtFilterMun.value;
                var layer = form.elements.filterByNeighbours.value;
                if (!layer)
                    layer = form.querySelector(":checked").value;//esto porque IE no soporta el attribute value de una colección de radiobutton
                
                var filter =  new TC.filter.EqualTo("municipio", txtMuni.trim().removeGTLT());                
                                
                if (!resultsLayer)
                    _self.map.addLayer({
                        id: "WFSSearchResults3",
                        type: TC.Consts.layerType.WFS,
                        url: _self._WFSURL,
                        version: "1.1.0",
                        stealth: true,
                        featureType: layer,
                        propertynames: 'idfosa',
                        properties: filter,
                        outputFormat: TC.Consts.format.JSON
                    }).then(function (layer) {
                        resultsLayer = layer;
                        layer.map.on(TC.Consts.event.LAYERUPDATE, _featuresUpdate);
                    });
                else {
                    resultsLayer.setVisibility(false);
                    resultsLayer.clearFeatures();
                    resultsLayer.map.off(TC.Consts.event.LAYERUPDATE, _featuresUpdate);
                    resultsLayer.map.on(TC.Consts.event.LAYERUPDATE, _featuresUpdate);
                    resultsLayer.properties = filter,
                    resultsLayer.featureType = layer,
                    resultsLayer.setVisibility(true);
                    resultsLayer.refresh();
                }
            };

            

            var form = _self.div.getElementsByTagName("form")[0];

            var submitForm = function () {
                if (validate(form) === true) {
                    form.classList.remove('was-validated');
                    filter(form);
                    return;
                }
                form.classList.add('was-validated');
            }

            form.addEventListener('submit', function (event) {
                event.preventDefault();
                event.stopPropagation();
                submitForm();                
            }, false);

            var cleanButton = _self.div.getElementsByClassName("tc-ctl-filter-cln");
            if (cleanButton.length > 0) {
                cleanButton = cleanButton[0];
                cleanButton.onclick = function () {
                    form.elements.buscar.disabled = true;
                    form.elements.txtFilterMun.disabled = true;                    
                    form.elements.buscar.title = "Seleccione una de las 2 opciones anteriores";
                    _self.disable();                    
                };
            }
            form.elements.filterByNeighbours.forEach(function (item)
            {
                item.onchange = function () {
                    if (this.checked) {
                        form.elements.buscar.title = "Filtrar fosas por " + this.nextSibling.innerText.toLowerCase()
                        if (this.value === "distinctFosasMunResidencia")
                            _layerListMunicipalities = "listMunipiosResidenciaFilter";
                        if (this.value === "distinctFosasMunNacimiento")
                            _layerListMunicipalities = "listMunipiciosNacimientoFilter";
                    }                              
                    form.elements.buscar.disabled = false;
                    form.elements.txtFilterMun.disabled = false;
                    form.elements.txtFilterMun.value = "";
                    TC.UI.autocomplete.call(_self.div.querySelector(".tc-ctl-filter-mun"), "clear");
                }
            })

            var _featuresUpdate = function (e) {
                if (e.layer.id === resultsLayer.id) {
                    e.layer.map.off(TC.Consts.event.LAYERUPDATE, _featuresUpdate);                    
                    //pintar resultados
                    if (e.newData.features && e.newData.features.length > 0) {
                        var _arrIDs = e.newData.features.reduce(function (init, val) {
                            init.push(val.properties.idfosa);
                            return init
                        }, []);                        
                        showSomeFeatures(_arrIDs);                        
                    }
                    else {
                        _self.map.toast("No hay resultados", { type: TC.Consts.msgType.WARNING });
                        _self.disable();
                    }
                }
            }
            var source = function (pattern) {                
                var filter = new TC.filter.IsLike("criterio", "*" + pattern.trim().removeDiacritics().removeGTLT() + "*", undefined, undefined, undefined, false); 
                return $.post(_self._WFSURL, {
                    service: "WFS",
                    version: "1.0.0",
                    request: "GetFeature",
                    properties:"municipio",
                    typeName: _layerListMunicipalities,
                    outputFormat: "application/json",
                    FILTER: filter.getText(),
                },null,"json");
                
            }
            form.elements.txtFilterMun.disabled = true;
            _createAutoComplete(_self.div.querySelector(".tc-ctl-filter-mun"), _self.div.querySelector(".tc-ctl-filter-auList"), source, false, function () {
                submitForm();
            });            
        });
    };
    _ctl.disable = function () {
        var _self = this;
        var form = _self.div.getElementsByTagName("form")[0];
        form.classList.remove('was-validated');
        if (_filtered)
            showAllFeatures();
    }
    var showAllFeatures = function () {
        //semaforo que indica si se está filtrando o no
        _filtered = false;
        _getGraveControls().forEach(function (item) {
            //obvio la capa de "otros lugares"
            if (item instanceof TC.control.OtherPlaces || item instanceof TC.control.MemoryPlaces) {
                item._workLayer.setVisibility(true);
                return;
            }
            item.getFeatures().forEach(function (feat) {
                if (!item._workLayer.getFeatureById(feat.id))
                    item._workLayer.addFeature(feat);            
            });
        });
    };
    var showSomeFeatures = function (_arrIDs) {
        //semaforo que indica si se está filtrando o no
        _filtered = true;
        //Elimino IDs duplicados
        _arrIDs = _arrIDs.filter(function (item, pos) {
            return _arrIDs.indexOf(item) == pos;
        });
        _getGraveControls().forEach(function (item) {
            //obvio la capa de "otros lugares"
            if (item instanceof TC.control.OtherPlaces || item instanceof TC.control.MemoryPlaces) {
                item._workLayer.setVisibility(false);
                return;
            }
                
            item.getFeatures().forEach(function (feat) {
                if (_arrIDs.indexOf(feat.data.id) < 0) {
                    if (item._workLayer.getFeatureById(feat.id))
                        item._workLayer.removeFeature(feat)
                }
                else {
                    if (!item._workLayer.getFeatureById(feat.id))
                        item._workLayer.addFeature(feat);
                    }                        
            
            })
            if (item._workLayer.features.length > 0 && item._workLayer.getVisibility() === false)
                item._workLayer.setVisibility(true);
        })
    };   
        
})();