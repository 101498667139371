TC.control = TC.control || {};

if (!TC.Control) {
    TC.syncLoadJS(TC.apiLocation + 'TC/Control');
}


TC.control.Graves = function (div, options) {
    var self = this;

    self._layerTitle = options.layerTitle ? options.layerTitle : "";
    self._filter = options.filter;
    self._iconUrl = options.iconURL;
    self._index = options.index;
    self._WFSURL = options.WFSURL;
    TC.Control.apply(self, arguments);
    //precarga de imágens
    $('<img/>')[0].src = self._iconUrl;
    $('<img/>')[0].src = self._iconUrl.toLowerCase().replace(".png", "2.png");
    $('<img/>')[0].src = "layout/fosas/imgs/areainfluencia.png";
}

TC.inherit(TC.control.Graves, TC.Control);

(function () {

    var _ctl = TC.control.Graves.prototype;

    var IDESTADOLUGAR = 4;
    var IDESTADOMEMORIA = 6;
    var _minNumForPaging = 20;
    var _minNumForFiltering = 30;
    var closeDialogResolveFnc = null;
    var areaLocation = null;
    var _renderingGraveInfo = false;
    var _fncFeaturesLoaded = null;
    var _features = null;
    var _iconSizes = {
        normal: {
            width: 18,
            height: 23
        },
        highlight: {
            width: 33,
            height: 43
        }
    }

    _ctl._workLayer = null;
    _ctl.template = {};

    _ctl.CLASS = 'tc-ctl-graves';
    _ctl.LoadEvent = "gravesloaded.tc";

    _ctl.itsMyJob = false;

    _ctl.$map = null;

    var getControlInstance = null;
     
    _ctl.template[_ctl.CLASS + "-legend"] = "js/tc/templates/GraveLegend.hbs";
    _ctl.template[_ctl.CLASS + "-info"] = "js/tc/templates/GraveInfo.hbs";
    _ctl.template[_ctl.CLASS + "-MPinfo"] = "js/tc/templates/MemoryPlaceInfo.hbs";
    _ctl.template[_ctl.CLASS + "-info-carousel"] = "js/tc/templates/GraveInfoCarousel.hbs";

    _ctl.register = function (map) {
        var self = this;
        _ctl.$map = map;
        return new Promise(function (resolve, reject) {
            TC.Control.prototype.register.call(self, map).then(function () {
                var idx = map.layers.length - 1;
                var guid = TC.getUID();
                var addLAyerPromise = new Promise(function (resolve, reject) {

                    self.getRenderedHtml(self.CLASS + '-legend', {
                        uid: guid,
                        title: self.options.layerTitle,
                        iconURL: self.options.iconURL
                    }, function (html) {
                        var layer = self._workLayer = new TC.layer.Vector({
                            "id": guid,
                            "title": self._layerTitle,
                            "url": self._WFSURL,
                            "type": "WFS",
                            "geometryName": "the_geom",
                            "featureType": "fosas",
                            "outputFormat": "JSON",
                            "properties": self._filter,
                            "customLegend": html,
                            "styles": {
                                marker: { url: self._iconUrl, anchor: [0.5, 1], width: _iconSizes.normal.width, height: _iconSizes.normal.width }
                            }
                        });
                        /* map.insertLayer(self._workLayer, self.options.index,function (layer) {
                             var layer = layer;
                             resolve(layer);
                         });*/
                        map.addLayer(self._workLayer, function (layer) {
                            console.log("--------------------------" + layer.title);
                            var layer = layer;
                            resolve(layer);
                            /*var pos = idx + self._index;
                            if (pos !== layer.map.layers.indexOf(layer))
                                layer.map.insertLayer(layer, pos);*/
                        });
                    });

                });
                var mapLoadedPromise = new Promise(function (resolve, reject) {
                    map.loaded(function () {
                        resolve();
                    })
                });

                getControlInstance = function () {
                    return self;
                }
                Promise.all([addLAyerPromise, mapLoadedPromise]).then(function (args) {
                    console.log("Grave registered" + args[0].title);
                    resolve();
                    _fncEveryIsLoaded(args[0], self);
                })

                self.getFeatures = function () {
                    return self._features;
                };
            })
        });

    };

    _ctl.getGraveById = function (id) {
        var _self = this;
        var arrControls = _self.$map.getControlsByClass(TC.control.Graves);
        var found = null;
        arrControls.forEach(function (graveCtrl, i) {
            if (found) return;
            found = graveCtrl.getFeatures().find(function (item) {
                return item.data["id"] == id;
            });
        });

        return found;
    }
    var _fncEveryIsLoaded = function (layer, ctrl) {
        var map = ctrl.map
        ctrl._heyBrotherAreYouThere();
    }

    function isEmpty(obj) {
        for (var prop in obj) {
            if (obj.hasOwnProperty(prop))
                return false;
        }

        return JSON.stringify(obj) === JSON.stringify({});
    }

    var _selectedFeature = null;
    var _lstMunicipios = null;

    var _featureOverHandler = _ctl.HighLightGrave = function (e) {
        var feature = e.feature
        if (feature.layer === areaLocation || !feature.layer.title) return;

        var abbr = _getLayerAbbr(feature.layer.title);

        feature.layer.map.getControlsByClass(TC.control.Graves).forEach(function (item) {
            if (feature.data.idestado === item.options.filter.expression) {
                feature.STYLETYPE = abbr;
                feature.options = { url: item.options.iconURL, width: _iconSizes.normal.width, height: _iconSizes.normal.height, anchor: [0.5, 1] };
            }
        });


        if (_selectedFeature)
            _selectedFeature.unselect();
        feature.select();
        _selectedFeature = feature;
        //dibujar el area de localización
        areaLocation.clearFeatures()
        if (_selectedFeature.data.radio) {
            areaLocation.addPolygon([CreateCircle(_selectedFeature.geometry, _selectedFeature.data.radio, 40)], {
                showsPopup: false
            });
        }

    };
    var _featureClickHandler = _ctl.OpenGraveInfo = function (e) {
        var layer = e.feature.layer;
        //este semáforo evita que se pinche en varios fosas antes de carga la info de una
        if (!_renderingGraveInfo) {
            _renderingGraveInfo = true;
            //cargar info de la fosa
            _renderGraveInfo(e.feature.data, layer.map)
        }
        else {
            layer.map.toast("Se está cargando la información de una fosa. Espere a que termine", { type: TC.Consts.msgType.WARNING });
        }

    };
    var _renderGraveInfo = function (graveData, map) {
        var esLugar = (graveData.idestado == IDESTADOLUGAR);
        var _data = graveData;

        var _self = getControlInstance();

        var addLayerPromises = []
        //cargar victimas
        if (graveData.idestado !== IDESTADOMEMORIA)
            addLayerPromises.push(map.addLayer({
                "id": TC.getUID(),
                "url": _self._WFSURL,
                "type": "WFS",
                "featureType": "victimas",
                "outputFormat": "JSON",
                "properties": esLugar ? new TC.filter.EqualTo("idlugar", graveData.id) : new TC.filter.EqualTo("idfosa", graveData.id),
                "stealth": true
            }));
        //cargar fuentes
        if (graveData.idestado !== IDESTADOMEMORIA)
            addLayerPromises.push(map.addLayer({
                "id": TC.getUID(),
                "url": _self._WFSURL,
                "type": "WFS",
                "featureType": "entidadesinformantes",
                "outputFormat": "JSON",
                "properties": new TC.filter.EqualTo("idfosa", graveData.id),
                "stealth": true
            }));
        //cargar fotografias
        addLayerPromises.push(map.addLayer({
            "id": TC.getUID(),
            "url": _self._WFSURL,
            "type": "WFS",
            "featureType": "fotografias",
            "outputFormat": "JSON",
            "properties": new TC.filter.Or(new TC.filter.EqualTo("idfosa", graveData.id), new TC.filter.EqualTo("idlugar", graveData.id)),
            "stealth": true
        }));

        var promisesContainer = {};
        var loadItemsPromises = [];

        var search = map.getControlsByClass(TC.control.Search)[0];
        loadItemsPromises.push(search.getMunicipalities());

        if (graveData.idestado !== IDESTADOMEMORIA) {
            loadItemsPromises.push(new Promise(function (resolve, reject) {
                promisesContainer["VictimsLoaded"] = { "resolve": resolve, "reject": reject };
            }));
            loadItemsPromises.push(new Promise(function (resolve, reject) {
                promisesContainer["SourcesLoaded"] = { "resolve": resolve, "reject": reject };
            }));
        }
        loadItemsPromises.push(new Promise(function (resolve, reject) {
            promisesContainer["PhotosLoaded"] = { "resolve": resolve, "reject": reject };
        }));

        var layerLoaded = function (e) {
            //añadir victimas al objeto de datos
            if (e.layer.featureType === "victimas") {
                var victims = e.newData.features
                _data.victimasIdentificadas = e.newData.features.length;
                _data.victimasDesconocidas = _data.totalvictimas === -1 ? -1 : (_data.totalvictimas - _data.victimasIdentificadas)
                _data.victims = []
                e.newData.features.forEach(function (item) {
                    _data.victims.push(item.properties)
                });
                promisesContainer["VictimsLoaded"]["resolve"].call(promisesContainer["VictimsLoaded"]);
            }
            //añadir fuentes consultadas
            if (e.layer.featureType === "entidadesinformantes") {
                var sources = e.newData.features
                _data.sources = []
                e.newData.features.forEach(function (item) {
                    _data.sources.push(item.properties)
                });
                promisesContainer["SourcesLoaded"]["resolve"].call(promisesContainer["SourcesLoaded"]);
            }
            //añadir fotografias
            if (e.layer.featureType === "fotografias") {
                var photos = e.newData.features
                _data.photos = []
                e.newData.features.forEach(function (item, i) {
                    _data.photos.push(Object.assign(item.properties, { index: i }));
                });
                promisesContainer["PhotosLoaded"]["resolve"].call(promisesContainer["PhotosLoaded"]);
            }
        };

        Promise.all(addLayerPromises).then(function () {
            map.on(TC.Consts.event.LAYERUPDATE, layerLoaded);
        })
        Promise.all(addLayerPromises.concat(new Promise(function (resolve) {
            closeDialogResolveFnc = resolve;
        }))).then(function (args) {
            var layer1 = args[0], layer2 = args[1], layer3 = args[2];
            for (var i in arguments) {
                var layer = arguments[i];
                if (layer)
                    try {
                        layer.map.removeLayer(layer);
                    } catch (e) { }
            }
        })

        Promise.all(loadItemsPromises).then(function (args) {
            var municipalities = args[0];
            var _map = map;
            map.off(TC.Consts.event.LAYERUPDATE, layerLoaded);

            var getMunicipalityById = function (id) {
                //TODO: polyfill
                return municipalities.filter(function (item) {
                    return item.id === id;
                })
            }
            _data.municipio = getMunicipalityById(_data.idmunicipio)[0].label;
            var template = graveData.idestado !== IDESTADOMEMORIA ? _ctl.CLASS + "-info" : _ctl.CLASS + "-MPinfo"
            _ctl.getRenderedHtml(template, _data).then(function (html) {
                var modal = $(html);
                modal.appendTo('body');
                modal.addClass(_ctl.CLASS);

                TC.Util.showModal(modal[0], {
                    closeCallback: function () {
                        modal.remove();
                        closeDialogResolveFnc();
                        _renderingGraveInfo = false;
                    }
                });
                _ctl.closeModal = function () {
                    modal.remove();
                    closeDialogResolveFnc();                    
                };                
                if (_data.victims) {
                    var table = $("#Tabla_Victimas").DataTable({
                        paging: _data.victims.length > _minNumForPaging,
                        ordering: true,
                        order: [[2, 'asc']],
                        info: false,
                        autoWidth: true,
                        searching: _data.victims.length > _minNumForFiltering, 

                        language: {
                            "url": "//cdn.datatables.net/plug-ins/9dcbecd42ad/i18n/Spanish.json"
                        },
                        columnDefs: [
                            {
                                type: 'diacritics-neutralise',
                                "targets": [1, 2, 3, 4, 5, 8]
                            }],
                        columns: [
                            { visible: true, width: "1%", sortable: false },
                            { visible: true, width: "10%" },
                            { visible: true, width: "20%" },
                            { visible: true, width: "19%" },
                            { visible: false, width: "9%" },
                            { visible: false, width: "9%" },
                            { visible: false, width: "9%" },
                            { visible: false, width: "9%" },
                            { visible: false, width: "9%" },
                            { visible: true, width: "5%", sortable: false }
                        ]
                    }).on('draw', function (event) {
                        table.column(0).visible(true);
                        table.column(0).visible(event.target.getElementsByClassName("tc-grave-icon").length > 0);                        
                    });
                }

                $('.botonera button', modal).on('click', function (e) {
                    e.preventDefault();
                    //comprobamos que no es el ultimo botón marcado
                    if (e.currentTarget.parentElement.getElementsByClassName("btn-success").length === 1 & e.currentTarget.className.indexOf("btn-danger") < 0)
                        return false;

                    // Get the column API object
                    var column = table.column($(this).attr('data-column'));
                    $(this).toggleClass("btn-success").toggleClass("btn-danger");
                    //cambiar el title
                    if (column.visible())
                        this.title = this.title.replace("Ocultar", "Mostrar");
                    else
                        this.title = this.title.replace("Mostrar", "Ocultar");
                    // Toggle the visibility
                    column.visible(!column.visible());
                });
                $(".tc-grave-icon", $("#Tabla_Victimas")).on('click', function (e) {
                    var id = $(this).data("graveid") | $(this).data("placeid");
                    var grave = _ctl.getGraveById(id);
                    if (grave)
                        _ctl.zoomToGrave(grave);
                    _ctl.closeModal();
                });
                if (_data.photos && _data.photos.length > 0) {
                    //crear carrusel
                    //$('#Imagenes').carousel();
                    modal[0].getElementsByClassName("photos")[0].onclick = function (e) {
                        _createCarousel(_data.photos);
                        //$('#Imagenes').show();
                    }
                }

            });
        })

    }
    _ctl._heyBrotherAreYouThere = function () {
        var _self = this;
        var _ctrls = _self.map.getControlsByClass(TC.control.Graves);

        //en esta lógica los controles graves compiten por ser el primero y hacer el resto de los procesos...
        //bindera eventos a las capas etc.
        if ((function (me) {
            if (_ctrls.find(function (c) {
                return c.itsMyJob;
            }))
                return false;
            else
                return me.itsMyJob = true;
        })(_self)) {

            var featAdded = function (e) {
                var arrControls = _self.$map.getControlsByClass(TC.control.Graves).forEach(function (ctrl) {
                    if (e.layer === ctrl._workLayer) {
                        ctrl._features = $.extend(true, [], ctrl._workLayer.features);
                        ctrl.$events.trigger($.Event(_self.LoadEvent, { layer: e.layer, features: ctrl._workLayer.features }));
                        ctrl._workLayerPromise()
                    }
                });
            }
            _self.map.on(TC.Consts.event.LAYERUPDATE, featAdded);
            _self.map.defaultInfoContainer = TC.Consts.infoContainer.POPUP;
            //reorder layer            
            var arrPromises = new Array(_ctrls.length);

            _ctrls.forEach(function (ctrl, i) {
                var prom = arrPromises[i] = new Promise(function (resolve, reject) {
                    ctrl._workLayerPromise = resolve;
                    if (ctrl._workLayer && ctrl._workLayer.features && ctrl._workLayer.features.length > 0) {
                        ctrl._features = $.extend(true, [], ctrl._workLayer.features);
                        ctrl.$events.trigger($.Event(_self.LoadEvent, { layer: ctrl._workLayer, features: ctrl._workLayer.features }));
                        resolve();
                    }
                });

            })
            $.when.apply($, arrPromises).then(function () {
                _self._bindingMapEvents();
                
                var firstPos = _self.map.layers.length;
                var ctrls = _self.map.getControlsByClass(TC.control.Graves).sort(function (ctrlA, ctrlB) {
                    if (firstPos > _self.map.layers.indexOf(ctrlA._workLayer))
                        firstPos = _self.map.layers.indexOf(ctrlA._workLayer);
                    if (firstPos > _self.map.layers.indexOf(ctrlB._workLayer))
                        firstPos = _self.map.layers.indexOf(ctrlB._workLayer);
                    return ctrlA.options.index - ctrlB.options.index;
                });

                var layerOrder = [];
                _self.map.layers.slice(firstPos, firstPos + ctrls.length).forEach(function (item) {
                    layerOrder.push(item.title);
                })
                console.info("Orden de capas:" + layerOrder.join(","));
                
                var i = 0;
                var funcOrderlayer = function (layer, index) {
                    console.info("moviendo " + layer.title + " a la posicion " + index);
                    _self.map.insertLayer(layer, index);
                };

                var _layerOrderEvent = function (evt) {
                    console.info("LAYERORDER " + evt.layer.title + " de " + evt.oldIndex + " a " + evt.newIndex);
                    var layerOrder = [];
                    _self.map.layers.slice(firstPos, firstPos + ctrls.length).forEach(function (item) {
                        layerOrder.push(item.title);
                    })
                    console.info("Orden de capas:" + layerOrder.join(","));
                    if (++i >= ctrls.length) {
                        _self.map.off(TC.Consts.event.LAYERORDER, _layerOrderEvent);
                        return;
                    }
                    setTimeout(function () {
                        funcOrderlayer(ctrls[i]._workLayer, firstPos + ctrls[i].options.index - 1);
                    }, 30);
                }

                _self.map.on(TC.Consts.event.LAYERORDER, _layerOrderEvent);

                funcOrderlayer(ctrls[i]._workLayer, firstPos + ctrls[i].options.index-1);
            });

        }
    }
    _ctl._bindingMapEvents = function () {
        var _self = this;

        _self.map.getControlsByClass(TC.control.Graves).forEach(function (item) {
            TC.Cfg.styles.selection[_getLayerAbbr(item.options.layerTitle)] = { url: (item.options.iconURL.toLowerCase().replace(".png", "2.png")), width: _iconSizes.highlight.width, height: _iconSizes.highlight.height, anchor: [0.5, 1] };
        });
        var search = _self.map.getControlsByClass(TC.control.Search)
        if (search.length > 0) {
            search = search[0];
            var municipios = search.getMunicipalities();
            if ($.isArray(municipios)) {
                _self.map.$events.on(TC.Consts.event.FEATUREOVER, _featureOverHandler);
                _self.map.$events.on(TC.Consts.event.FEATURECLICK, _featureClickHandler);
            }
            else
                municipios.then(function (municipios) {
                    _lstMunicipios = municipios;
                    _self.map.$events.on(TC.Consts.event.FEATUREOVER, _featureOverHandler);
                    _self.map.$events.on(TC.Consts.event.FEATURECLICK, _featureClickHandler);
                });
        }

        //crear capa area de localización
        var guid = TC.getUID();
        _ctl.getRenderedHtml(_ctl.CLASS + '-legend', {
            uid: guid,
            title: "Posible área de localización",
            iconURL: "layout/fosas/imgs/areainfluencia.png"
        }, function (html) {
            var legend = _self.map.getControlsByClass(TC.control.Legend)
            var html = html;
            _self.map.addLayer({
                "id": guid,
                "title": "Posible área de localización",
                "type": TC.Consts.layerType.VECTOR,
                "customLegend": html,
                "stealth": true,
                "styles": {
                    "polygon": {
                        fillColor: "#FFB71C",
                        fillOpacity: 0.1,
                        strokeColor: "#FFB71C",
                        strokeWidth: 2,
                        lineDash: [5, 5]
                    }
                }

            }, function (layer) {
                areaLocation = layer;
                if (legend.length > 0) {
                    legend = legend[0];
                    var li = document.createElement("li");
                    legend.div.getElementsByClassName("tc-ctl-legend-branch")[0].appendChild(li);
                    li.outerHTML = html;
                }
                _ctl.ClearAreaLocation = function () {
                    areaLocation.clearFeatures();
                }
            });
        });

        var idFosa = TC.Util.getParameterByName("IDFosa");
        if (idFosa) {
            var found = _self.getGraveById(idFosa);
            if (found) {
                _renderGraveInfo(found.data, _self.$map);
                //centrar el mapa
                _self.$map.setCenter(found.geometry);
                _self.zoomToGrave(found);
            }
        }
    }

    _ctl.render = function () {
    };
    _ctl.zoomToGrave = function (e) {
        var CONST_MIN_RESOLUTION_ZOOMING = 0.5;
        if (!e.data.radio) {
            e.layer.map.setCenter(e.geometry, { animate: true }).then(function () {
                e.layer.map.setResolution(CONST_MIN_RESOLUTION_ZOOMING);
            });
        }
        else {
            var currentExtent = e.layer.map.getExtent();
            var width = currentExtent[2] - currentExtent[0];
            var height = currentExtent[3] - currentExtent[1];
            var ratio = CONST_MIN_RESOLUTION_ZOOMING / e.layer.map.getResolution();
            if (width * ratio < e.data.radio * 2 * 1.2 || height * ratio < e.data.radio * 2 * 1.2) {
                e.layer.map.zoomToFeatures([new TC.feature.Polygon(CreateCircle(e.geometry, e.data.radio, 10))]);
            }
            else {
                var _w = width * ratio;
                var _h = height * ratio;
                e.layer.map.setExtent([e.geometry[0] - (_w / 2), e.geometry[1] - (_h / 2), e.geometry[0] + (_w / 2), e.geometry[1] + (_h / 2)]);
            }
        }

    }
    var _createCarousel = function (photos) {
        _ctl.getRenderedHtml(_ctl.CLASS + "-info-carousel", photos, function (html) {
            var playingVideo = null;
            $(document.body).append(html);
            document.body.getElementsByClassName("imageClose")[0].onclick = function () {
                if (this.remove)
                    this.parentNode.remove()
                else
                    this.parentNode.parentNode.removeChild(this.parentNode);
            }
            var videos = document.getElementsByTagName("video");
            for (var i = 0; i < videos.length; i++) {               
                videos[i].addEventListener('play', function () {
                    $(".carousel").carousel("pause");
                    playingVideo = this;
                }, false);
            }
            (function () {
                var video = null;
                var promisePlay = null;
                $('#miCarrusel').on('slide.bs.carousel', function (event) {
                    if (playingVideo) {
                        playingVideo.pause();
                    };
                    //video = event.relatedTarget.querySelector("video");
                    //if (video) {                        
                    //    promisePlay = video.play();
                    //    if (promisePlay) {
                    //        //Older browsers may not return a promise, according to the MDN website
                    //        promisePlay.then(function(){
                    //            promisePlay=null;
                    //        }).catch(function (error) {                                
                    //            if (error.name === "NotSupportedError")
                    //                console.log(video.querySelector("source").type + " no soportado");
                    //            else
                    //                console.error(error);
                    //        });
                    //    }
                    //}
                })
            })();

        });
    }
    var _getLayerAbbr = function (title) {
        var abbr = '';
        title.split(' ').forEach(function (word) { abbr = abbr + (word.substr(0, 1).toLowerCase()) })
        return abbr;
    };
    _ctl.clearFeatures = function () {
        var _self = this;
        _self._workLayer.clearFeatures();
    }
    _ctl.proccessLayerLoaded = function (e) {
        var _self = this;
        if (e.layer.features && e.layer.features.length) {
            _self._features = $.extend(true, [], e.layer.features);
        }
        _self.$events.trigger($.Event(_self.LoadEvent, { layer: e.layer, features: e.newData.features }));
    }
})();
