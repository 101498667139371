TC.control = TC.control || {};

if (!TC.Control) {
    TC.syncLoadJS(TC.apiLocation + 'TC/Control');
}




TC.control.SearchPlaces = function (div,options) {

    var self = this;    
    self._WFSURL = options.url;
    TC.Control.apply(self, arguments);
    
}

TC.inherit(TC.control.SearchPlaces, TC.Control);

(function () {
    var _ctl = TC.control.SearchPlaces.prototype;
    var arrGravesPlaces = [];       
    
    var ctlResultsPanel = null;
    var resultsLayer = null;
    var _gravesCtrls = null;//array de controles de tipo TC.control.Graves    
    var _selectedFeature = null;
    var _highlightClass = "tc-highlight";
    _ctl.template={};

    _ctl.CLASS = 'tc-ctl-searchPlaces';

    _ctl.getFoundedFeat = null;

    var getControlInstance = null;    

    _ctl.template[_ctl.CLASS] = "js/tc/templates/SearchPlaces.hbs";  
        
    _ctl.register = function (map) {
        var self = this;
        return new Promise(function (resolve) {            

            Promise.all([TC.Control.prototype.register.call(self, map)
                , self.renderPromise()
                , autocompleteLoadPromise()
            ]).then(function () {
                console.log("Search Places registered");
                resolve();
                _dependenciesLoaded(map);
            })
        })
        
    };

    _ctl.render = function () {
        var self = this;
        getControlInstance = function () {
            return self;
        }
        var renderPromises = new Promise(function (resolve, reject) {
            self.renderData(null, function () {
                resolve(self);
            });
        });
        return renderPromises;
    };
    
    var _renderAutocomplete = function (map) {
        _gravesCtrls = map.getControlsByClass(TC.control.Graves);
        var arrPromises = new Array(_gravesCtrls.length)

        _gravesCtrls.forEach(function (item, i) {
            arrPromises[i] = new Promise(function (resolve) {
                item.$events.on(item.LoadEvent, function (e) {
                    console.log("layer cargada: " + e.layer.title);
                    e.features.forEach(function (item) {
                        arrGravesPlaces[arrGravesPlaces.length] = item.data.denominacion;
                    })
                    resolve();
                });           
            });
            
        });

        Promise.all(arrPromises).then(function () {
            _ctl.getDenomValue = _createAutoComplete(document.getElementById("txtDenomSeachPlaces"), document.querySelector(".tc-ctl-searchPlaces-auList"), arrGravesPlaces, true, function () {                
                sendQuery(true);
            });
        });
        var search = map.getControlsByClass(TC.control.Search)[0];        
        $.when(municipalities = search.getMunicipalities()).then(function (municipalities) {
            _ctl.getMunicipalityValue = _createAutoComplete(document.getElementById("txtMunSeachPlaces"), document.querySelector(".tc-ctl-searchPlaces-auList"), municipalities, false, function () {
                sendQuery();
            });
            _ctl.getMunicipalityById = function (id) {
                //TODO: polyfill
                return municipalities.filter(function (item) {
                    return item.id === id;
                })
            }
        });

        $(".tc-ctl-searchPlaces-auList").on('keydown', function (e) {
            _keyUpDownEvent(e, $(e.currentTarget).data("control"), $(e.currentTarget));
        });

    }
    var _dependenciesLoaded = function (map) {
        _renderAutocomplete(map);
        var _self = getControlInstance();
        //bindeamos botones
        /*var searchButton = _self.div.getElementsByClassName("tc-ctl-searchPlaces-btn");
        if (searchButton.length > 0) {
            searchButton = searchButton[0];            
            searchButton.onclick = function () {
                if (validate())
                    sendQuery();
            };
        }*/
        var form = _self.div.getElementsByTagName("form")[0];
        form.addEventListener('submit', function (event) {
            event.preventDefault();
            event.stopPropagation();
            if (validate() === true) {
                form.classList.remove('was-validated');
                sendQuery();
                return;
            }
            form.classList.add('was-validated');
        }, false);
        var cleanButton = _self.div.getElementsByClassName("tc-ctl-searchPlaces-cln");
        if (cleanButton.length > 0) {
            cleanButton = cleanButton[0];
            cleanButton.onclick = function () {
                var inputs = _self.div.querySelectorAll("input");
                for (var i = 0;i<inputs.length;i++){
                    TC.UI.autocomplete.call(inputs[i], "clear");
                }
                
                //$(_self.div.getElementsByTagName("input")).val("");
                if (_self.removeResultsPanel) _self.removeResultsPanel();
                _self.div.getElementsByTagName("form")[0].classList.remove('was-validated');

            };
        }

    }    
    var autocompleteLoadPromise = function () {
        return new Promise(function (resolve, reject) {
            if (!TC.UI || !TC.UI.autocomplete)
                TC.loadJS(
                    true,
                    [TC.apiLocation + 'TC/ui/autocomplete.js'],
                    function () {
                        console.log("autocomplete loaded");
                        resolve();
                    });
            else
                resolve();
        });
        
    }
    
    var _keyUpDownEvent = function (e, control, listCtrl) {
        if (!e.ctrlKey && !e.altKey && !e.shiftKey) {            
            if (e.keyCode === 40) { // down arrow                
                if (control[0] == document.activeElement) {
                    listCtrl.find('li:first a').focus();
                }
                else if (listCtrl.find('li:last a').is(':focus')) {
                    // Scenario 2: We're focused on the last li; move up to search input
                    control.focus();
                }
                else {
                    // Scenario 3: We're in the list but not on the last element, simply move down                    
                    listCtrl
                        .find('li > a:focus')
                        .parent('li')
                        .next()
                        .find('a').focus();
                }
                e.preventDefault(); // Stop page from scrolling
                e.stopPropagation();
            }
            else if (e.keyCode === 38) { // up arrow                
                if (control[0] == document.activeElement) {
                    // Scenario 1: We're focused on the search input; move down to the last li
                    listCtrl.find('li:last a').focus();
                } else if (document.activeElement == listCtrl.find('li:not([header]):first a').get(0)) {
                    listCtrl.find('li:not([header]):last a').focus();
                } else {
                    // Scenario 3: We're in the list but not on the first element, simply move up
                    listCtrl
                        .find('li > a:focus')
                        .parent('li')
                        .prev()
                        .find('a').focus();
                }
                e.preventDefault(); // Stop page from scrolling
                e.stopPropagation();
            }
        }
    }

    var _createAutoComplete = function (control, listCtrl, source,refreshOnChange, callback) {
        var fnc2;
        var listCtrl = listCtrl;
        var fnc = function () {
            if (!fnc2)
                return null;
            return fnc2(control.id);
        }

        TC.UI.autocomplete.call(control,{
            minLength: 0,
            target: listCtrl,
            source: function (request, response) {
                request = request.trim();
                var _self = this;
                _self.target.innerHTML ='<li><a class="tc-ctl-search-li-loading" href="#">Buscando...<span class="tc-ctl-search-loading-spinner tc-ctl-search-loading"></span></a></li>';
                _self.target.classList.remove("tc-visible");

                var matcher = new RegExp($.fn.dataTable.util.escapeRegex(request), "i");
                var _possibleValues=$.grep(source, function (value) {
                    value = value.label || value.value || value;
                    return matcher.test(value) || matcher.test(value.removeDiacritics());
                });
                if (_possibleValues.length > 0) {
                    response(_possibleValues);
                    if (refreshOnChange)
                        (function (data, id) {
                            var colData = {}
                            colData[id] = data
                            fnc2 = function (id) {
                                return colData[id];
                            };
                        })(request, control.id);
                }
                else
                    this.target.innerHTML = '<li><a title="Revise el criterio" class="tc-ctl-search-li-empty">No se han encontrado resultados</a></li>';
            },
            callback: function (e) {
                control.value=(e.currentTarget.text);                    
                this.target.classList.remove("tc-visible");
                var value = $(e.currentTarget).data("value");
                (function (data, id) {
                    var colData={}
                    colData[id]=data
                    fnc2= function (id) {
                        return colData[id];
                    };
                })(e.currentTarget.dataset["value"], control.id);
                if (callback)
                    callback();
            },
            buildHTML: function (data) {
                var pattern = control.value.trim();
                this.target.style.maxHeight = "";               

                $(this.target).data("control",control);
                this.target.classList.add("tc-visible");

                //calculo el desplazamiento hacia arriba de la lista de coincidencias para que quede justo rebajo del cuadro de texto
                this.target.style.marginTop = null;
                var margin = Math.round(this.target.getBoundingClientRect().top - control.getBoundingClientRect().top - control.getBoundingClientRect().height)
                this.target.style.marginTop = "-" + margin + "px"

                if (data.results.length > 1)
                    return data.results.reduce(function (pv, cp, i) {
                        return (i > 1 ? pv : _highlightText(pv, pattern)) + _highlightText(cp, pattern);
                    });
                else
                    return _highlightText(data.results[0], pattern);
            }
        });
        control.addEventListener("targetCleared.autocomplete", function () {
            listCtrl.classList.remove("tc-visible");
            fnc2 = null;
        });
        control.addEventListener('keypress', function (e) {
            if (e.which == 13 && refreshOnChange) {
                e.preventDefault();
                e.stopPropagation();
                sendQuery();
                setTimeout(function () {
                    listCtrl.classList.remove("tc-visible");
                }, 200);
            }
        });
        control.addEventListener("search", function (e) {
            if (control.value.length === 0) {
                TC.UI.autocomplete.call(e.target, "clear");
            }
        });
        control.addEventListener("input", function (e) {
            if (control.value.length === 0) {
                TC.UI.autocomplete.call(e.target, "clear");
            }
        });
        control.addEventListener("change", function () {
            if (refreshOnChange)
                (function (data, id) {
                    var colData = {}
                    colData[id] = data
                    fnc2 = function (id) {
                        return colData[id];
                    };
                })(this.value, control.id);
            if (this.value.length === 0) {
                TC.UI.autocomplete.call(this, "clear");
            }
        });
        // Detect up/down arrow
        control.addEventListener('keydown', function (e) {
            _keyUpDownEvent(e, $(control), $(listCtrl))
        });
        return fnc;
    }
        
    var _highlightText = function (value, pattern) {
        pattern = new RegExp(pattern, "gi");
        var id = value?value.id:null;
        value = value.label || value.value || value;
        return "<li><a href=\"#\" data-value=\"" + (id?id:value) + "\">" + value.replace(pattern, '<b>$&</b>') + "</a></li>";
    };

    var validate = function () {
        var _self = getControlInstance();;
        if (_self.getMunicipalityValue() || _self.getDenomValue())
            return true;        
        _self.map.toast("Debe especificar al menos uno de los criterios", { type: TC.Consts.msgType.WARNING });        

        return false;
    };
    var sendQuery = function (exactDenomtext) {
        var _self = getControlInstance();

        var form = _self.div.getElementsByTagName("form")[0];
        form.classList.remove('was-validated');

        if (_self.removeResultsPanel) _self.removeResultsPanel();
        //cerrar el panel de resutados de la búsqueda de victimas
        var _ctrlSearchVictims = _self.map.getControlsByClass(TC.control.SearchVictims)[0];
        if (_ctrlSearchVictims && _ctrlSearchVictims.removeResultsPanel) _ctrlSearchVictims.removeResultsPanel();

        var _filters = _self.map.getControlsByClass(TC.control.Filter)[0];
        if (_filters) _filters.disable();
        
        var filter = null;        

        if (_self.getMunicipalityValue())
            filter = new TC.filter.EqualTo("idmunicipio", _self.getMunicipalityValue());
        if (_self.getDenomValue())
        {
            var _denomFilter = null;
            if (exactDenomtext)
                _denomFilter = new TC.filter.EqualTo("denominacion",_self.getDenomValue());
            else{
                var pattern = _self.getDenomValue().trim();
                pattern = pattern.replace(/a|á/gi, "[aáAÁ]");
                pattern = pattern.replace(/e|é/gi, "[eéEÉ]");
                pattern = pattern.replace(/i|í/gi, "[iíïIÍ]");
                pattern = pattern.replace(/o|ó/gi, "[oóOÓ]");
                pattern = pattern.replace(/u|ú/gi, "[uúüUÚ]");
                pattern = pattern.replace(/\</gi, "&lt;").replace(/\>/gi, "&gt;");
                _denomFilter=new TC.filter.EqualTo(new TC.filter.Function("strMatches", [{ "PropertyName": "denominacion" }, { "Literal": "(?i).*"+ pattern + ".*" }]),true);
            }                
                                    
            filter = !filter ? _denomFilter : new TC.filter.And(filter, _denomFilter);
        }  

        var _featuresUpdate = function (e) {
            if (e.layer.id === "WFSSearchResults") {
                e.layer.map.off(TC.Consts.event.LAYERUPDATE, _featuresUpdate);
                //pintar resultados
                if (e.layer.features.length===0)
                    _self.map.toast(_self.getLocaleString("noResults"), { type: TC.Consts.msgType.WARNING });
                else {
                    var fncCallback = function () {
                        
                        if (e.layer.features.length === 1) {
                            var feat=_gravesCtrls[0].getGraveById(e.layer.features[0].data.id);
                            _gravesCtrls[0].zoomToGrave(feat);
                            return;
                        }                            
                        var arrFeat = [];
                        e.layer.features.forEach(function (elem) {
                            var feat = _gravesCtrls[0].getGraveById(elem.data.id);
                            if (feat) {
                                arrFeat.push(feat);
                                if (feat.data.radio)
                                    arrFeat.push(new TC.feature.Polygon(CreateCircle(feat.geometry, feat.data.radio, 10)))
                            }                            
                        });
                        if (arrFeat.length)
                            e.layer.map.zoomToFeatures(arrFeat);
                    }
                    _drawResults(e.layer.features, fncCallback);
                    //compruebo si el panel de herramientas ocupa el 100% de la pantalla, si es así lo repliego
                    if (document.getElementById("tools-panel").clientHeight === document.body.clientHeight)
                        document.getElementById("tools-panel").classList.add("right-collapsed");

                }
            }
        };
        var _drawResults = function (features, fncCallback) {

            $(".tc-ctl-searchPlaces-auList").removeClass("tc-visible");


            var layer = features[0].layer;
            features.forEach(function (item) {
                item.data["municipio"] = _self.getMunicipalityById(item.data["idmunicipio"])[0].label;
            });

            _self.getFoundedFeat = function () {
                return features;
            };

            var deferResultsPanel = new Promise(function (resolve) {
                if (!TC.control.ResultsPanel) {
                    TC.loadJS(true, TC.apiLocation + 'TC/control/ResultsPanel', function () {
                        resolve(TC.control.ResultsPanel);
                    });
                }
                else
                    resolve(TC.control.ResultsPanel);
            });            

            deferResultsPanel.then(function (ResultsPanel) {
                new Promise(function (resolve, reject) {
                    if (!ctlResultsPanel) {
                        var ccontainer = _self.map.getControlsByClass(TC.control.ControlContainer);
                        if (ccontainer.length > 0)
                            ccontainer[0].addControl("ResultsPanel", {
                                "side": "right",
                                "options": {
                                    "div": "results-panel-places"                                    
                                },
                                "content": "table",
                                "titles": {
                                    "main": "",
                                    "max": ""
                                },
                                "save": {
                                    "fileName": "Lugares de la memoria" + ".xls"
                                }
                            }).then(function (ctl) {
                                ctlResultsPanel = ctl;
                                resolve();
                            });
                        else {
                            ctlResultsPanel = new TC.control.ResultsPanel({
                                "div": "results-panel-places",
                                "content": "table",
                                "titles": {
                                    "main": "",
                                    "max": ""
                                },
                                "save": {
                                    "fileName": "Lugares de la memoria" + ".xls"
                                },
                                "resize":true
                            });
                            _self.map.addControl(ctlResultsPanel).then(function () {
                                resolve();
                            });
                        }

                    }
                    else {
                        resolve();
                    }
                }).then(function () {
                    _self.removeResultsPanel = function () {
                        if (ctlResultsPanel) {
                            ctlResultsPanel.close();
                        }
                    }
                    //es necesario borrar de la cache de dust la plantilla de la tabla por que si se ha utilizado antes la busqueda de victimas estará con la plantillad este control
                    //delete dust.cache[ctlResultsPanel.CLASS + "-table"];
                    ctlResultsPanel.template[ctlResultsPanel.CLASS + "-table"] = "js/tc/templates/SearchPlacesResults.hbs";
                    
                    ctlResultsPanel.div.querySelector(".prpanel-title-text").innerHTML = (features.length + (features.length > 1 ? " resultados" : " resultado"));                    

                    console.log("pinta panel de resultados");
                    //TODO: limpiar resultados previos
                    ctlResultsPanel.openTable({
                        data: (features.length > 1 ?
                            features.reduce(function (vi, va, index) {
                                return (vi instanceof Array ? vi : [vi.data]).concat([va.data])
                            })
                            :
                            [features[0].data]),
                        css: {
                            trClass: "trClass",
                            tdClass: "tdClass",
                            thClass: "thClass",
                        },
                        callback: function (tabla) {
                            var col = jQuery("tbody tr", tabla);
                            col.on("click", function () {
                                var index = $(this).data("index");
                                if (index != undefined) {
                                    var feat = _gravesCtrls[0].getGraveById(layer.features[index].data.id);
                                    _gravesCtrls[0].zoomToGrave(feat);
                                }
                            }).mouseenter(function () {
                                jQuery("tr", tabla).removeClass(_highlightClass);
                                $(this).addClass(_highlightClass);
                                var id = $(this).data("id");
                                for (var i = 0; i < _gravesCtrls.length; i++) {
                                    var f = _gravesCtrls[i].getGraveById(id);
                                    if (f) {
                                        _gravesCtrls[i].HighLightGrave({
                                            feature: f
                                        })
                                        _selectedFeature = f;
                                        break;
                                    }
                                }

                            }).mouseleave(function () {
                                if (_selectedFeature)
                                    _selectedFeature.unselect();
                                $(this).removeClass(_highlightClass);
                                _gravesCtrls[0].ClearAreaLocation();
                            });

                            _self.highlightRow = function (index) {
                                jQuery("tr", tabla).removeClass(_highlightClass)
                                var col = jQuery("tbody tr:nth-child(" + (index + 1) + ")", tabla)
                                col.addClass(_highlightClass);

                            }
                            _self.unhighlightRow = function () {
                                jQuery("tr", tabla).removeClass(_highlightClass);
                            }

                            var col = jQuery("tbody button", tabla);
                            col.on("click", function (e) {
                                var index = $(this).parents("tr").data("index")
                                if (index != undefined) {
                                    var f = _gravesCtrls[0].getGraveById(layer.features[index].data.id);
                                    _gravesCtrls[0].OpenGraveInfo({ feature: f });
                                }
                                e.preventDefault(); // Stop page from scrolling
                                e.stopPropagation();

                            })
                            fncCallback();
                            setTimeout(function () {
                                //se deshabilita el swype para que se pueda hacer scroll horizontal del panel de resultados
                                if (TC.browserFeatures.touch())
                                    ctlResultsPanel._$div.swipe("disable");
                            }, 1000);
                        }
                    });
                })
            });
            
        };
        if (!resultsLayer) {
            _self.map.addLayer({
                id: "WFSSearchResults",
                type: TC.Consts.layerType.WFS,
                url: _self._WFSURL,
                version: "1.1.0",
                stealth: true,
                geometryName: "the_geom",
                featureType: "fosas",
                propertynames: 'id,denominacion, descripcionestado, idmunicipio',
                properties: filter,
                outputFormat: TC.Consts.format.JSON,
                styles: [
                    {
                        point: {
                            radius: 0
                        }
                    }]
            }).then(function (layer) {
                resultsLayer = layer;
                layer.map.on(TC.Consts.event.LAYERUPDATE, _featuresUpdate);
            });
            _self.map.$events.on(TC.Consts.event.RESULTSPANELCLOSE, function (e) {
                if (TC.browserFeatures.touch())
                    e.control._$div.swipe("enable");                    
                resultsLayer.clearFeatures();
                resultsLayer.setVisibility(false);
                _self.map.off(TC.Consts.event.LAYERUPDATE, _featuresUpdate);
            });
        }
            
        else
        {
            resultsLayer.setVisibility(false);
            resultsLayer.clearFeatures();
            resultsLayer.map.off(TC.Consts.event.LAYERUPDATE, _featuresUpdate);
            resultsLayer.map.on(TC.Consts.event.LAYERUPDATE, _featuresUpdate);
            resultsLayer.properties = filter;
            resultsLayer.setVisibility(true);
            resultsLayer.refresh();
        }
    };
    
    

})();