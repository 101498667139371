TC.control = TC.control || {};

if (!TC.Control) {
    TC.syncLoadJS(TC.apiLocation + 'TC/Control');
}


TC.control.Counters = function (div, options) {
    var self = this;
    
    TC.Control.apply(self, arguments);

}

TC.inherit(TC.control.Counters, TC.Control);

(function () {
    var _ctl = TC.control.Counters.prototype;
    
    _ctl.template = {};
    _ctl.CLASS = 'tc-ctl-counters';

    //templates
    _ctl.template[_ctl.CLASS] = "js/tc/templates/Counters.hbs";
    _ctl.template[_ctl.CLASS + "-info"] = "js/tc/templates/CountersInfo.hbs";    

    _ctl.register = function (map) {
        var self = this;
        return new Promise(function (resolve, reject) {
            TC.Control.prototype.register.call(self, map).then(function () {
                console.log("counters registered");
                resolve();
                _dependenciesLoaded(self);
            })
        });
    };

    _ctl.render = function () {
        var _self = this;

        _self.renderData(null, function () {
        });
        
    };
    var _dependenciesLoaded = function (self) {
        const _self = self;
        return new Promise(function (resolve) {
            var _counters = {
                graves: {
                    total: 0,
                    byType: {
                        prospected: 0,
                        inspected: 0,
                        notInspected: 0
                    }
                },
                places: {
                    total: 0,
                    memory: 0
                },
                victims: {
                    total: 0,
                    unknown: 0
                }
            }
            var arrGravesPlaces = [];
            _gravesCtrls = _self.map.getControlsByClass(TC.control.Graves);
            var arrPromises = new Array(_gravesCtrls.length)

            _gravesCtrls.forEach(function (item, i) {
                arrPromises[i] = new Promise(function (resolve) {
                    item.$events.on(item.LoadEvent, function (e) {
                        //graves
                        if (e.target instanceof TC.control.GraveInspected ||
                            e.target instanceof TC.control.GraveNotInspected ||
                            e.target instanceof TC.control.GraveProspected) {
                            _counters.graves.total = _counters.graves.total + e.target.getFeatures().length;
                            _counters.victims.total = _counters.victims.total + e.target.getFeatures().reduce(function (pV, cV) {
                                return pV + (cV.data.totalvictimas > 0 ? cV.data.totalvictimas : 0);
                            }, 0);
                            if (e.target instanceof TC.control.GraveInspected)
                                _counters.graves.byType.inspected = e.target.getFeatures().length;
                            if (e.target instanceof TC.control.GraveNotInspected)
                                _counters.graves.byType.notInspected = e.target.getFeatures().length;
                            if (e.target instanceof TC.control.GraveProspected)
                                _counters.graves.byType.prospected = e.target.getFeatures().length;
                        }
                        //places
                        else {
                            _counters.places.total = _counters.places.total + e.target.getFeatures().length;
                            if (e.target instanceof TC.control.MemoryPlaces)
                                _counters.places.memory = e.target.getFeatures().length;
                        }
                        resolve();
                    });
                });
            });
            arrPromises[arrPromises.length] = new Promise(async function (resolve, reject) {
                wfsLayer = new TC.layer.Vector({
                    type: TC.Consts.layerType.WFS,
                    url: "ogc/wfs",
                    stealth: true
                });
                var capabilities = true;
                try {
                    capabilities = await wfsLayer.getCapabilitiesPromise();
                }
                catch (err) {
                    reject(err);
                }

                /*TC.ajax({
                    url: "ogc/wfs",
                    method: 'POST',
                    contentType: TC.Consts.mimeType.XML,
                    responseType: TC.Consts.mimeType.XML,
                    data: TC.Util.WFSQueryBuilder("victimas", new TC.filter.NotEqualTo("idfosa", 9999), capabilities, null, true, _self.map.getCRS(), null)
                }).then(resolve).catch(reject);*/
                TC.ajax({
                    url: "ogc/wfs",
                    method: 'GET',
                    contentType: TC.Consts.mimeType.XML,
                    responseType: TC.Consts.mimeType.XML,
                    //data: TC.Util.WFSQueryBuilder("victimas", new TC.filter.NotEqualTo("idfosa", 9999), capabilities, null, true, _self.map.getCRS(), null)
                    data: {
                        service: "WFS", 
                        request: "GetFeature",
                        version: "2.0.0",
                        outputFormat: "JSON",
                        srsname: _self.map.getCRS(),
                        typenames: "victimas",
                        resultType: "hits",
                        filter: new TC.filter.NotEqualTo("idfosa", 9999).getText()
                    }
                }).then(resolve).catch(reject);                
            })


            Promise.all(arrPromises).then(function (responses) {
                const numVitimsKnown = parseInt(responses[responses.length - 1].data.querySelector("FeatureCollection").getAttribute("numberMatched"), 10);
                _counters.victims.unknown = _counters.victims.total - numVitimsKnown;
                _counters.victims.known = numVitimsKnown;
                _self.getRenderedHtml(_ctl.CLASS + '-info', _counters, function (html) {
                    _self.div.querySelector("div").innerHTML=html;
                });
                
            });
        })
        
    }
})();